
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ErrorMessageHandlerMixin from "../../misc/ErrorMessageHandler.mixins";
import ContainerStatistics from "@/models/ContainerStatistics";
import Container from "@/models/Container.model";
import ContainerType from "@/models/ContainerType.model";
import StatisticsData from "@/models/StatisticsData";

@Component({
  components: {
    LineChartComponent: () => import(
      /* webpackChunkName: "LineChartComponent" */
      '@/components/Misc/LineChart.component.vue'
    ),
  }
})
export default class ContainerStatisticsComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop({required: true})
  private container!: ContainerType;

  @Prop({required: true})
  private chartColor!: string;

  @Prop({required: true})
  private statistics!: ContainerStatistics;

  @Prop({default: false})
  private isLoading!: boolean;

  /**
   * flag that indicates if the graph is shown
   * @private
   */
  private showGraph = false;

  /**
   * returns a percentage number based on the value that is passed
   * @param value
   * @private
   */
  private formattedPercentageNumber(value?: number | 'NaN' | 'Infinity' | '-Infinity') {
    if(!value && value != 0 || value === 'NaN' || value === 'Infinity' || value === '-Infinity') return '-';
    return this.$formatPercentage(value);
  }

  /**
   * returns the value or a translation if the value is null
   * @param value
   * @private
   */
  private getSafeValue(value?: number | 'NaN' | 'Infinity' | '-Infinity') {
    if(!value && value != 0 || value === 'NaN' || value === 'Infinity' || value === '-Infinity') return '-';
    return value;
  }

  private getSafeRoundedValue(value?: number | 'NaN' | 'Infinity' | '-Infinity') {
    if(!value && value != 0 || value === 'NaN' || value === 'Infinity' || value === '-Infinity') return '-';
    return Math.round(value);
  }

  /**
   * goes back to previous page
   * @private
   */
  private onBackPressed() {
    this.$router.go(-1);
  }

  /**
   * returns the statistic data, if there is no data creates dummy object with empty values array
   * @private
   */
  private get statisticData() {
    return this.statistics?.containersInCirculationData ?? StatisticsData.parseFromObject({values:[]});
  }

  private mounted() {
    // sets a time out and activates the graph, somehow the apex charts plugin has a problem with sizing when we don't
    // use a delay
    setTimeout(() => this.showGraph = true, 100);
  }

  /**
   * returns the dates of the container statistics
   * @private
   */
  private get xScalingData() {
    return this.statistics?.containersInCirculationData?.dates ?? [];
  }
}

